<template>
    <div class="root-wrap">
        <div class="body-wrap">
            <div class="login_logo fx-center">
                <van-image :src="require('@/assets/logo.jpg')" width="220" height="200"></van-image>
            </div>
            <van-cell-group style="margin-top: 20px;" :border="false">
                <van-field v-model="form.phone" type="number" label="手机号" placeholder="请输入手机号" center clearable ref="phone" :maxlength="11" style="height: 50px;"></van-field>
                <van-field v-model="form.verifyCode" type="number" label="验证码" placeholder="请输入验证码" center clearable ref="verifyCode" :maxlength="6" style="height: 50px;">
                    <template #button>
                        <van-button size="small" type="primary" plain @click="handleVerifyCode" :disabled="disabledVerify" :loading="loadingVerify" loading-text="获取验证码">{{verifyText}}</van-button>
                    </template>
                </van-field>
            </van-cell-group>
            <div class="login_btn">
                <van-button type="primary" :disabled="disabledLogin" round block @click="login">绑 定</van-button>
                <div class="fx-row fx-center">
                    <van-checkbox v-model="checked" shape="square" icon-size="18px" style="margin-right: 8px;"></van-checkbox>
                    <span>绑定即同意</span><a class="login_link" :href="protocolUrl">《益享优福注册协议》</a>
                </div>
            </div>
        </div>
        <div class="footer-wrap fx-center">
            <div style="color: #FF4500;font-size: 15px;" @click="$goPage('index')">暂不登录,去逛逛</div>
        </div>
        <div class="utils-wrap">
        </div>
    </div>
</template>

<script>
import { validField } from '@/libs/common-utils/common-utils';
import router from '@/router';
import { mapGetters, mapActions } from 'vuex';
import store from '@/store';
import { wxLogin } from '@/libs/wx-utils';

export default {
    data() {
        return {
            form: {
                phone: "",
                verifyCode: "",
            },
            verifyText: '获取验证码',
            disabledVerify: false,
            loadingVerify: false,
            checked: true,
            protocolUrl: 'https://m.fulijiayuan.com.cn/agree',
            
        }
    },
    computed: {
        ...mapGetters([
            'platform',
            'verifyTime',
            'openId',
            'openData',
            'baseData'
        ]),
        disabledLogin() {
            for (let key in this.form) {
                if (this.form[key] == "") {
                    return true;
                }
            }
            return false;
        }
    },
    methods: {
        ...mapActions([
            'sendVerifyCode',
            'loginByVerify'
        ]),
        handleVerifyCode() {
            if (this.form.phone == "") {
                this.$toast('请输入手机号码');
                return false;
            }
            let valid = validField(this.form.phone, 'phoneNumber');
            if (!valid) {
                this.$toast('请输入正确的手机号码');
                return;
            }
            this.loadingVerify = true;
            this.sendVerifyCode({ mobile: this.form.phone, opendata: this.openData }).then(() => {
                this.$toast.success('发送成功');
                this.$refs.verifyCode.focus();
                this.loadingVerify = false;
            }).catch(({errorMsg}) => {
                this.$toast.fail(errorMsg)
                this.loadingVerify = false;
            });
        },
        closeTimer() {
            clearInterval(this.timer);
            this.timer = null;
        },
        login() {
            if (this.form.phone == "") {
                this.$toast('请输入手机号码');
                return false;
            }
            let valid = validField(this.form.phone, 'phoneNumber');
            if (!valid) {
                this.$toast('请输入正确的手机号码');
                return;
            }

            if (this.form.verifyCode == "") {
                this.$toast('请输入验证码')
                return false;
            }
            this.loginByVerify({ mobile: this.form.phone, verifyCode: this.form.verifyCode, opendata: this.openData }).then((res) => {
                console.log('loginByVerify rsp:'+res)
                if (res) {
                    this.$goPage('gzh');
                } else {
                    this.$goPage('index')
                }
            }).catch(({ errorCode, errorMsg }) => {
                this.$toast.fail(errorMsg);
            });
        },
    },
    watch: {
        'verifyTime': {
            immediate: true,
            handler(val) {
                let _this = this;
                if (val && this.timer == null) {
                    setTimeout(() => {
                        this.timer = setInterval(tempFuc(), 1000);
                    }, 10);
                }
                function tempFuc() {
                    let currentDateTime = new Date().getTime();
                    let dateTimeDiff = val - currentDateTime;
                    if (dateTimeDiff > 1000) {
                        _this.verifyText = `${parseInt(dateTimeDiff / 1000)}秒后获取`;
                        _this.disabledVerify = true;
                    } else {
                        _this.disabledVerify = false;
                        _this.verifyText = '获取验证码'
                        // _this.setVerifyDateTime(null);
                        _this.closeTimer();
                    }
                    return tempFuc;
                }
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        console.log('beforeRouteEnter')
        let href = `${location.protocol}//${location.host}${router.options.base || ''}${to.fullPath}?redirect=${from.fullPath}`
        let platform = store.getters.platform;
        console.log(href)
        console.log(platform)
        if (platform == 'wx') {
            wxLogin(href).then((login) => {
                console.log('1111')
              console.log(login)
                if (login) {
                    console.log('已经登录成功替换当前页面 跳转走111 ->', to.query.redirect);
                    router.replace('/index')
                    // next({
                    //     path: to.query.redirect,
                    //     replace: true
                    // });
                } else {
                    next();
                }
            }).catch((prevent) => {
                !prevent ? next() : '';
            });
        } else {
            next();
        }
    }
}
</script>

<style lang="scss" scoped>
.root-wrap {
	background-color: #fff;
}
.login_btn {
	padding: 45px 20px;
    > * {
        margin-bottom: 10px;
    }
}

.login_logo {
    margin: 0 auto;
    padding: 40px 0;
}

.gzh-wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > div {
        background-color: #fff;
        width: 80%;
    }
}
</style>
